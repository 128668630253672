(function($) {

	AOS.init();



	$( ".page-template.side-menu-active .side-menu" ).first().css( "display", "block" );



	/* To the top
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	$(".back-to-top").click(function(){
      $("html, body").animate({ scrollTop: 0 }, 600);
      return false;
  });



	if($('.testimonials-two-block .slider').length) {
		$('.testimonials-two-block .slider').slick({
			slidesToShow: 1,
	  	slidesToScroll: 1,
			autoplaySpeed: 4000,
			arrows: true,
			nextArrow: '.arrow-next',
		  prevArrow: '.arrow-prev'
		});
	}



	$('#sbi_images').append('<a class="anchor" target="_new" href="https://instagram.com/markedwards222"><div class="icon"></div><div class="title">Join my Instagram for regular updates</div><div class="mobile-title">Open Instagram</div><div class="text">@markedwards222</div></a>');




	/* Swipe Menu (Custom Moblie Menu)
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	$('#mobile-menu').mmenu({
    extensions: ["border-full", "pagedim-black", "position-right"],
		navbar:
		{
			title	:	" "
		},
		offCanvas: {
				pageSelector: ".site-wrap"
		},
		scrollBugFix: {
    	fix:true,
    },
		slidingSubmenus: false,
		navbars: [
			 {
					"position": "top",
					"content": [
						 "breadcrumbs"
					]
			 }
		]
  });



	$sweetMenu = $('.dropdown-f-menu');

  $sweetMenu.on('click', function (event) {
      $this = $(this);
      if ($(event.target).hasClass('toggle')) {
        event.preventDefault();
        $this.toggleClass('open');
      }
  });

  $sweetMenu.hover(null,function () {
      $(this).removeClass('open');
  });




	/* Sticky Header
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	// var headerHeight = $('.top-nav').outerHeight() + $('header').outerHeight();
	var headerHeight = $('header').outerHeight();

	$(window).scroll(function() {
		var scroll = $(window).scrollTop();
		headerHeight = $('header').outerHeight() + $('header').outerHeight();

		if(scroll > headerHeight){
			$('header').addClass('Sticky');
			$('body').addClass('menu_is_sticky');
		}else{
			$('header').removeClass('Sticky');
			$('body').removeClass('menu_is_sticky');
		}

		if(scroll > 700){
			$('.back-to-top').addClass('visible');
		}else{
			$('.back-to-top').removeClass('visible');
		}

	});


	$( ".mburger-mobilemenu" ).click(function() {
		$(".mm-menu").removeClass('mm-menu_opened');
		$("html").removeAttr("class");
		$(".site-wrap").removeAttr("style");
	});

	$( ".mburger-normal" ).click(function() {
		$(".mm-menu").addClass('mm-menu_opened');
		$("html").addClass("mm-wrapper_opened");
		$("html").addClass("mm-wrapper_blocking");
		$("html").addClass("mm-wrapper_background");
		$("html").addClass("mm-wrapper_opening");
	});

	/* Accordion
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	var allPanels = $('.accordion-block .item .answer').hide();
	var question = $('.accordion-block .item .question');

	$('.accordion-block .item .question a').click(function() {
		allPanels.slideUp();
		question.removeClass( "active" );
		$(this).parent().next().slideDown();
		$(this).parent().addClass( "active" );
		return false;
	});

	$(".accordion-block .item .question:first").addClass('active');
	$(".accordion-block .item .answer:first").show();

})( jQuery );
